document.addEventListener('turbolinks:load', event => {
  //console.log('turbolinks:load in Google Analytics javascript');
  //console.log('event.target.title',event.target.title)
  //console.log('event.data.url',event.data.url)
  
  return gtag('config', 'G-KT4L2VQBMC', {
    page_title: event.target.title,
    page_location: event.data.url,
  }
  );
});
